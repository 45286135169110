import { Box } from '@dex/uikit'
import styled from 'styled-components'

export const StyledPriceChart = styled(Box)<{ $isDark: boolean; $isExpanded: boolean }>`
  border: none;
  border-radius: 32px;
  width: 100%;
  padding-top: 36px;

  ${({ theme }) => theme.mediaQueries.sm} {

    background-clip: padding-box,border-box;
    background-image: ${({ theme }) => theme.colors.gradients.cardBackground};
    background-origin: padding-box,border-box;
    border: 2px solid transparent;

    padding-top: 8px;
    border-radius: ${({ $isExpanded }) => ($isExpanded ? '0' : '16px')};
    width: ${({ $isExpanded }) => ($isExpanded ? '100%' : '50%')};
    height: ${({ $isExpanded }) => ($isExpanded ? 'calc(100vh - 100px)' : '516px')};
  }
`

StyledPriceChart.defaultProps = {
  height: '70%',
}
